/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
//import 'vuetify/styles'
import 'vuetify/dist/vuetify.min.css';

// Composables
import { createVuetify } from 'vuetify'

import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'

import locales from './locales'

// import {
//   // VDataTable,
//   VDataTableServer,
//   VDataTableVirtual,
// } from "vuetify/labs/VDataTable";



// import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  ssr: true,
  components: {
    // VDataTable,
    // VDataTableServer,
    // VDataTableVirtual,
    // VSkeletonLoader,
  },
  locale: {
    adapter: createVueI18nAdapter(locales),
  },
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
          colors: {
            "primary": '#B3947F',
            "secondary": '#f3f3f3',
            "brown": "#483B33",
            "light-brown": "#E4CCBB",
            "brown-light": "#E4CCBB",
            "PENDING" : "#ECCF04",
            "COMPLETED": "#656DDB",
            "DISPUTED": "#F4261B",
            "CANCELED": "#4D4E5C"
          },
      },
      light: {
        colors: {
          "primary": '#B3947F',
          "secondary": '#f3f3f3',
          "brown": "#483B33",
          "light-brown": "#E4CCBB",
          "brown-light": "#E4CCBB",
          "PENDING" : "#ECCF04",
          "COMPLETED": "#656DDB",
          "DISPUTED": "#F4261B",
          "CANCELED": "#4D4E5C"
        },
    },
    },
  },
})
