/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'

import PaginatedTable from '@/components/common/PaginatedTable'
import SimpleTable from '@/components/common/SimpleTable'
import DialogSimple from '@/components/common/DialogSimple'
import ObjDebug from '@/components/common/ObjDebug'
import moment from 'moment'
import it from "moment/dist/locale/it"
import en from 'moment/src/locale/en-gb'
import { createHead } from 'unhead'

createHead()
const env = import.meta.env
const app = createApp(App)

registerPlugins(app)

app.mount('#app')

app.component('paginated-table', PaginatedTable)
app.component('simple-table', SimpleTable)
app.component('dialog-simple', DialogSimple)
app.component('obj-debug', ObjDebug)



const currentVersion = localStorage.getItem('version');
let isnew = true;

if(currentVersion){
    isnew = currentVersion != env.VITE_APP_VERSION
}

if(isnew){
    console.log('New version detected', env.VITE_APP_VERSION)
    localStorage.removeItem('search');
    localStorage.removeItem('book_single');
    localStorage.removeItem('paymentId');
    sessionStorage.clear();
}

localStorage.setItem('version', env.VITE_APP_VERSION);


let locale = localStorage.getItem('locale');
if(locale == 'it'){
    moment.updateLocale("it", it)
}else{
    moment.updateLocale("en", en)
}


app.config.globalProperties.$moment = moment
app.config.globalProperties.$env = env




