import apiClient from '@/plugins/apiClientDrive'
import moment from 'moment';
import qs from 'qs';

export default {
    namespaced: true,
    state:{
        success: null,
        errors: null,
        message: null,
        single: (localStorage.getItem('book_single')) ? JSON.parse(localStorage.getItem('book_single')) : {
            "carId": null,
            "quote": {},
            "start": {
                "date": null,
                "latitude": null,
                "longitude": null,
                "placeId": null,
                "country": null,
                "address": null
            },
            "end": {
                "date": null,
                "latitude": null,
                "longitude": null,
                "placeId": null,
                "country": null,
                "address": null
            },
            "extras": [],
            "drivers": [],
        }
    },
    getters:{
        success: state => state.success,
        errors: state => state.errors,
        message: state => state.message,
        single: state => state.single,
    },
    mutations:{
        error(state, err) {
            console.log('mutation error',err);
            state.message = "err.response.data.message";
            state.errors = "err.response.data.errors";
            if(err.response){
                console.log('mutation error response',err.response);
                if(err.response.data){
                    console.log('mutation error response data',err.response.data);
                    state.message = err.response.data.message;
                    state.errors = err.response.data.error;
                }
            }
            state.success = false;
        },
        success(state, data){
            console.log('mutation success',data);
            state.message = null;
            state.errors = null;
            state.success = true;
        },
        message(state, data) {
            console.log('mutation message',data);
            state.message = data;
        },
        reset(state){
            console.log('mutation reset');
            state.errors = {}
            state.message = null
            state.success = null
            localStorage.removeItem('book_single');
        },
        setSingle(state, data) {
            console.log('mutation setSingle startBooking BOOK', data);
            localStorage.setItem('book_single', JSON.stringify(data) );
            state.single = data;
        },
        setQuotation(state, data) {
            console.log('mutation setQuotation BOOK', data);
            state.single.quote = data;
            localStorage.setItem('book_single', JSON.stringify(state.single) );
        },
        checkDriversNumber(state){

            let hasExtraDrivers = state.single.extras.map(e => e.id).indexOf(6);
            console.log('mutation hasExtraDrivers', hasExtraDrivers);

            let drivers_number = 1;

            if(hasExtraDrivers >= 0){
                let extraDrivers = state.single.extras[hasExtraDrivers]
                console.log('mutation hasExtraDrivers extraDrivers', extraDrivers);
                drivers_number = drivers_number+extraDrivers.quantity;
            }

            console.log('checkDriversNumber drivers_length', state.single.drivers.length);
            console.log('checkDriversNumber drivers_n', drivers_number);

            if(drivers_number > state.single.drivers.length ){
                let tocreate = drivers_number - state.single.drivers.length

                console.log('checkDriversNumber tocreate', tocreate);


                for (let index = 0; index < tocreate; index++) {

                    let driver = {
                        "firstName": null,
                        "lastName": null,
                        "email": null,
                        "phone": null,
                        "city": null,
                        "country": null,
                        "main": false,
                    }

                    state.single.drivers.push(driver)
                }

            }


            if(drivers_number < state.single.drivers.length){
                let toremove = state.single.drivers.length - drivers_number

                console.log('checkDriversNumber toremove', toremove);

                for (let index = 0; index < toremove; index++) {
                    state.single.drivers.pop()
                }

            }

            if(state.single.drivers.length == 0) {

                let driver = {
                    "firstName": null,
                    "lastName": null,
                    "email": null,
                    "phone": null,
                    "city": null,
                    "country": null,
                    "main": false,
                }

                state.single.drivers.push(driver);
            }
            
            state.single.drivers[0].main = true;

            console.log('mutation setExtra', state.single);
            localStorage.setItem('book_single', JSON.stringify(state.single) );

        },
        setExtra(state, data){

            let extra = state.single.extras.map(e => e.id).indexOf(data.id);


            if(extra >= 0){
                console.log('setExtra',extra);
                console.log('setExtra',data);

                state.single.extras[extra] = data;

                if(data.quantity == 0){
                    state.single.extras.splice(extra,1)
                }

            }else{
                state.single.extras.push(data);
            }
            
            console.log('mutation setExtra', state.single);
            localStorage.setItem('book_single', JSON.stringify(state.single) );

        }
    },
    actions:{
        resetErrors({commit}){
            commit('reset')
        },
        setSingle({commit}, data){
            commit('setSingle', data);
        },
        setExtra({commit}, data){
            commit('setExtra', data);
            commit('checkDriversNumber');
        },
        setQuotation({commit}, data){
            commit('setQuotation', data);
            commit('checkDriversNumber');
        },
        setSingleFromSelected({commit}, data){

            
            let book = {
                "carId": data.car.id,
                "start": {
                    "date": data.search.start.date,
                    "latitude": data.search.start.lat,
                    "longitude": data.search.start.lon,
                    "placeId": data.search.start.loc,
                    "country": data.search.start.cou,
                    "address": data.search.start.description,
                },
                "end": {
                    "date": data.search.end.date,
                    "latitude": data.search.end.lat,
                    "longitude": data.search.end.lon,
                    "placeId": data.search.end.loc,
                    "country": data.search.end.cou,
                    "address": data.search.end.description,
                },
                "extras": [],
                "drivers": [],
                "details": data.details,
                "quote": data.quote,
            }


            commit('setSingle', book);
        },
    },
}