import it from './it.js'
import en from './en.js'
import pt from './en.js'
import ru from './en.js'

export const languages = {
     it: it,
     en: en,
     pt: en,
     ru: en,
}