import apiClient from '@/plugins/apiClient'
let basePath = "/pages/categories";
import qs from 'qs';

export default {
     namespaced: true,
     state:{
          list: {
               car_brand: [],
               car_type: [],
          },
          single: null,
          errors: {},
          message: {},
     },
     getters:{
          list: state => state.list,
          brands: state => state.list.car_brand,
          types: state => state.list.car_type,
          single: state => state.single,
          message: state => state.message,
          errors: state => state.errors,
     },
     mutations:{
        error(state, err) {
            console.log('mutation error',err);
            if(err.response){
               state.errors = err.response.data
            }else{
                 state.errors = err
            }
        },
        setCategories(state, resp) {
          console.log('mutation setList',resp);
          state.list = resp
        },
        setList(state, resp) {
            console.log('mutation setList',resp);
            state.list = resp.data
        },
        setMeta(state, resp){
          console.log('mutation setMeta',resp.meta);
          state.meta = resp.meta
        },
        setFilters(state, resp){
          console.log('mutation setFilters',resp.filters);
          state.filters = resp.filters
        },
        setSingle(state, resp){
               console.log('mutation setSingle',resp);
               state.single = resp
               state.errors = {}
               state.message = {}
        }
     },
     actions:{
          getList({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET LIST :'+data)
                    apiClient.get(basePath).then(resp => {
                         commit('setCategories', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getSingle({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET Single:'+data)
                    apiClient.get(basePath+'/'+data).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setCategories({state}, data){
               state.list = data;
          },
          setAsSingle({state}, data){
               state.single = data;
          },
          setMeta({commit}, data){
               console.log('action meta',data);
               commit('setMeta',{meta:data});
               // commit('success', true);
          },
          setFilters({commit}, data){
               console.log('action setFilters',data);
               commit('setFilters',{filters:data});
          },
          resetSingle({commit}){
               commit('setSingle', null);
          }
     },
}