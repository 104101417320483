import { createI18n, useI18n } from 'vue-i18n'
import { languages } from '@/locales/index.js'

const messages = Object.assign(languages)

if(!localStorage.getItem('locale')){
  localStorage.setItem('locale', navigator.language.substr(0,2))
}

const firstLocale = localStorage.getItem('locale')

const i18n = new createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: firstLocale,
  fallbackLocale: 'en',
  messages,
})

const locales = { i18n, useI18n }
export default locales