import apiClient from '@/plugins/apiClient'
let basePath = "/account";

export default {
     namespaced: true,
     state:{
          account: localStorage.getItem('account'),
          errors: {},
          message: null,
     },
     getters:{
          account: state => (state.account) ? JSON.parse(state.account) : null,
          message: state => state.message,
          errors: state => state.errors,
     },
     mutations:{
          error(state, err) {
               console.log('mutation error',err);
               state.errors = err.response.data
          },
          setAccount(state, resp){
               console.log('mutation setAccount',resp.data);
               localStorage.setItem('account', JSON.stringify(resp.data))
               state.account = JSON.stringify(resp.data)
          }
     },
     actions:{
         
          getAccount({commit}){
               return new Promise((resolve, reject) => {
                    console.log('GET Account')
                    apiClient.get(basePath).then(resp => {
                         commit('setAccount', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET LIST', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          
          updateAccount({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('UPDATE Account: ', data)
                    apiClient.patch(basePath+"/update", data).then(resp => {
                         resolve(resp);
                         commit('setAccount', resp.data);
                     })
                     .catch(err => {
                         console.log('ADMIN UPDATE Account ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
     }
}