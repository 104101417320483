import axios from 'axios';
import Raven from 'raven-js';
import store from '@/store/index';
import router from "@/router";

const env = import.meta.env;
/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl =  "https://maps.googleapis.com/maps/api", contentType = 'application/json' ) => {

    const options = {
        baseURL: baseUrl,
        headers : {
            'Content-Type': contentType,
        //     // "Accept": 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Credentials': true,
        //     // "Access-Control-Allow-Headers": '*',
        }
    };

    store.dispatch('loading/set',true);
    
    
    console.log('API CLIENT options ' , options)

    const client = axios.create(options);

    // Add a request interceptor
    client.interceptors.request.use(
        requestConfig => requestConfig,
        (requestError) => {
            Raven.captureException(requestError);
            return Promise.reject(requestError);
        },
    );

    // Add a response interceptor
    client.interceptors.response.use(function (response) {
        if(response.status === 201){
            store.dispatch('snackBars/addSnack', {message: response.data.message, type: 'success', err: null})
        }
        if(response.status === 202){
            store.dispatch('snackBars/addSnack', {message: "SAVED", type: 'success', err: null})
        }
        if(response.status === 204){
            store.dispatch('snackBars/addSnack', {message: "DELETED", type: 'error', err: null})
        }
        store.dispatch('loading/set',false);
        return response;
    },
    (error) => {
        if(!error.response){
            store.dispatch('snackBars/addSnack', {message: 'errors.api-connection'+' '+error, type: 'error', err: 'invalid API'})
        }else{
            console.error(error.response)
        if (error.response.status === 401) {
            store.dispatch('snackBars/addSnack', {message: error.response.data.message, type: 'error', err: '401'})
            store.dispatch('auth/forceLogout')
                .then(() => {
                    if (router.currentRoute.path !== '/cms/login') {
                        router.push('/')
                    }
                })
        }

        if (error.response.status === 403) {
            store.dispatch('snackBars/addSnack', {message: error.response.data.message, type: 'error', err: '403'})
            store.dispatch('auth/forceLogout')
            if (router.currentRoute.path !== '/cms/login') {
                router.push('/')
            }
        }

        if (error.response.status === 405 ) {
            store.dispatch('snackBars/addSnack', {message: error.response.data.message, type: 'error', err: '405'})
        }

       if (error.response.status === 422) {
           store.dispatch('snackBars/addSnack', {message: error.response.data.message, type: 'error', err: '422', errors: error.response.data.errors})
       }

       if (error.response.status === 404) {
        // router.push({name:"404"})
        store.dispatch('snackBars/addSnack', {message: error.response.data, type: 'error', err: '404'})
       }


       if (error.response.status === 429) {
        store.dispatch('snackBars/addSnack', error.response.data)
        }

        if (error.response.status >= 500) {
            store.dispatch('snackBars/addSnack', error.response.data)
        }
        
    }

        store.dispatch('loading/set',false);
        return Promise.reject(error);
    });

    return client;
};

class ApiClient {
    constructor(baseUrl = null) {
        this.client = getClient(baseUrl);
    }

    get(url, conf = {}) {
        return this.client.get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    delete(url, conf = {}) {
        return this.client.delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    head(url, conf = {}) {
        return this.client.head(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    options(url, conf = {}) {
        return this.client.options(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    post(url, data = {}, conf = {}) {
        return this.client.post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    put(url, data = {}, conf = {}) {
        return this.client.put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    patch(url, data = {}, conf = {}) {
        return this.client.patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
    // Provide request methods with the default base_url
    get(url, conf = {}) {
        console.log('API CLIENT | GET ' , url, conf)
        return getClient().get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    delete(url, conf = {}) {
        console.log('API CLIENT | DELETE ' , url, conf)
        return getClient().delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    head(url, conf = {}) {
        console.log('API CLIENT | HEAD ' , url, conf)
        return getClient().head(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    options(url, conf = {}) {
        console.log('API CLIENT | OPTIONS ' , url, conf)
        return getClient().options(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    post(url, data = {}, conf = {}) {
        console.log('API CLIENT | POST ' , url, conf)
        return getClient().post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    upload(url, data = {}, conf = {}) {
        console.log('API CLIENT | UPLOAD ' , url, conf)
        var baseUrl =  env.VITE_APP_API_URL;
        return getClient(baseUrl,'multipart/form-data').post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    s3upload(url, data = {}, conf = {}) {
        console.log('API CLIENT | UPLOAD ' , url, conf)
        var baseUrl = env.VUE_APP_S3_URL;
        return getClient(baseUrl,'multipart/form-data').post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    put(url, data = {}, conf = {}) {
        console.log('API CLIENT | PUT ' , url, conf)
        return getClient().put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    patch(url, data = {}, conf = {}) {
        console.log('API CLIENT | PUT ' , url, conf)
        return getClient().patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },
};
