export default [
    {
        path: 'login',
        name: 'account.login',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Login.vue'),
        meta: {
            driveAuth: false,
        },
    },
    {
        path: 'forgot-password',
        name: 'account.forgot-password',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/ForgotPassword.vue'),
        meta: {
            driveAuth: false,
        },
    },
    {
        path: 'register',
        name: 'account.register',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Register.vue'),
        meta: {
            driveAuth: false,
        },
    },
    {
        path: 'my',
        name: 'account.my',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Account.vue'),
        meta: {
            driveAuth: true,
        },
    },
    {
        path: 'my/settings',
        name: 'account.my.settings',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Settings.vue'),
        meta: {
            driveAuth: true,
        },
    },
    {
        path: 'my/drivers',
        name: 'account.my.drivers',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Drivers.vue'),
        meta: {
            driveAuth: true,
        },
    },
    {
        path: 'my/documents',
        name: 'account.my.documents',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Documents.vue'),
        meta: {
            driveAuth: true,
        },
    },
    {
        path: 'my/orders',
        name: 'account.my.orders',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Orders.vue'),
        meta: {
            driveAuth: true,
        },
    },
    {
        path: 'my/orders/:orderId',
        name: 'account.my.orders.single',
        params: 'orderId',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/OrderSingle.vue'),
        meta: {
            driveAuth: true,
        },
    },
    {
        path: 'my/search',
        name: 'account.my.search',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Search.vue'),
        meta: {
            driveAuth: true,
        },
    }
];