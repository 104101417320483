
import Vuex from 'vuex'
import snackBars from "@/store/modules/snackBars";
import auth from "@/store/modules/auth/";
import driveAuth from "@/store/modules/drive/auth/";
import driveAuthDrivers from "@/store/modules/drive/auth/drivers.js";
import driveAuthOrders from "@/store/modules/drive/auth/orders.js";
import driveAuthDocuments from "@/store/modules/drive/auth/documents.js";
import carsDrive from "@/store/modules/drive/cars/";
import driveSearch from "@/store/modules/drive/search/";
import driveBooking from "@/store/modules/drive/booking/";
import driveLeads from "@/store/modules/drive/leads/";
import loading from "@/store/modules/loading/";
import assets from '@/store/modules/assets/';
import myaccount from '@/store/modules/myaccount/';
import AdminPages from '@/store/modules/admin/pages/';
import AdminCategories from '@/store/modules/admin/categories/';
import AdminUsers from '@/store/modules/admin/users/';
import AdminSettings from '@/store/modules/admin/settings/';
import pages from '@/store/modules/pages/';
import categories from '@/store/modules/categories/';
import settings from '@/store/modules/settings/';
import gmaps from '@/store/modules/gmaps/';
import cookies from '@/store/modules/cookies/';

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
     gmaps,
     cookies,
     snackBars, 
     auth,
     driveAuth,
     driveSearch,
     driveAuthDrivers,
     driveAuthOrders,
     driveAuthDocuments,
     driveBooking,
     driveLeads,
     carsDrive,
     loading,
     assets,
     myaccount,
     pages,
     categories,
     settings,
     AdminPages,
     AdminUsers,
     AdminCategories,
     AdminSettings
  }
})