import apiClient from '@/plugins/apiClientDrive'
import qs from 'qs';

export default {
     namespaced: true,
     state:{
        errors: null,
        message: null,
        single: null,
        meta: {
            page: 1,
            totalPages: 1,
            totalElements: 0,
        },
        list: [],
        brands: (sessionStorage.getItem('brands')) ? JSON.parse(sessionStorage.getItem('brands')) : [],
        types: (sessionStorage.getItem('types')) ? JSON.parse(sessionStorage.getItem('types')) : [],
        models: [],
        fuels: [],
        powers: [],
        drives: [],
        seats: [],
        language: localStorage.getItem('locale'),
        transmissions: [],
        trunks: [],
        externalColors: [],
     },
     getters:{
        errors: state => state.errors,
        message: state => state.message,
        list: state => state.list,
        meta: state => state.meta,
        single: state => state.single,
        brands: state => state.brands,
        types: state => state.types,
        models: state => state.models,
        fuels: state => state.powers,
        powers: state => state.powers,
        drives: state => state.drives,
        seats: state => state.seats,
        transmissions: state => state.transmissions,
        trunks: state => state.trunks,
        externalColors: state => state.externalColors,
     },
     actions:{
        getBrands({commit}, online=""){

            if(online){
                online = "?online=true"
            }

            return new Promise((resolve, reject) => {
                console.log('GET CARS BRANDS')
                apiClient.get("cars/brands"+online).then(resp => {
                        commit('setBrands', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getTypes({commit}, online=""){

            if(online){
                online = "?online=true"
            }

            return new Promise((resolve, reject) => {
                console.log('GET CARS Types')
                apiClient.get("cars/types"+online).then(resp => {
                        commit('setTypes', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car Types error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getModels({commit}, online=""){
            if(online){
                online = "?online=true"
            }
            return new Promise((resolve, reject) => {
                console.log('GET CARS Models')
                apiClient.get("cars/models"+ online).then(resp => {
                        commit('setModels', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car Models error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getFuels({commit}){
            return new Promise((resolve, reject) => {
                console.log('GET CARS Fuel')
                apiClient.get("cars/powers").then(resp => {
                        commit('setFuels', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car Fuels error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getSeats({commit}){
            return new Promise((resolve, reject) => {
                console.log('GET CARS Fuel')
                apiClient.get("cars/seats").then(resp => {
                        commit('setSeats', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car Fuels error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getExternalColors({commit}){
            return new Promise((resolve, reject) => {
                console.log('GET CARS ExternalColors')
                apiClient.get("cars/external-colors").then(resp => {
                        commit('setExternalColors', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car ExternalColors error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getList({commit}, data){
            return new Promise((resolve, reject) => {
                console.log('GET CARS', data)
                let params =  qs.stringify(data, { arrayFormat: 'indices' })
                apiClient.get("cars?"+params,).then(resp => {
                        commit('setList', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car Types error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        searchSingle({state, commit}, car_id){
            return new Promise((resolve, reject) => {
                console.log('GET CARS Types')
                apiClient.get("search/cars/"+car_id+'?language='+state.language).then(resp => {
                        commit('setSingle', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car Types error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        }
     },
     mutations:{
        error(state, err) {
            console.log('mutation error',err);
            state.message = err.response.data.message;
            state.errors = err.response.data.errors;
        },
        setBrands(state, data) {
            console.log('mutation setBrands',data);
            state.brands = data;
            sessionStorage.setItem('brands', JSON.stringify(state.brands));
        },
        setTypes(state, data) {
            console.log('mutation setTypes',data);
            state.types = data;
            sessionStorage.setItem('types', JSON.stringify(state.types));
        },
        setModels(state, data) {
            console.log('mutation setModels',data);
            state.models = data;
        },
        setFuels(state, data) {
            console.log('mutation setFuels',data);
            state.powers = data;
            state.fuels = data;
        },
        setSeats(state, data) {
            console.log('mutation setSeats',data);
            state.seats = data;
        },
        setExternalColors(state, data) {
            console.log('mutation setExternalColors',data);
            state.externalColors = data;
        },
        setSingle(state, data) {
            console.log('mutation setSingle',data);
            state.single = data.fleet[0];
        },
        setList(state, data) {
            console.log('mutation setList',data);
            state.list = data.content
            state.meta.page = data.page
            state.meta.totalPages = data.totalPages
            state.meta.totalElements = data.totalElements
        }
    }
}