import commons from './it/commons.json'
import attributes from './it/attributes.json'
import models from './it/models.json'
import account from './it/account.json'
import settings from './it/settings.json'
import errors from './it/errors.json'
import car from './it/car.json'
import cookie from './it/cookie.json'
import bookingstepper from './it/bookingstepper.json'
import orders from './it/orders.json'
import pages from './it/pages.json'
import checkin from './it/checkin.json'
import notifications from './it/notifications.json'
import { it } from 'vuetify/locale'


const lang = {
    $vuetify: it,
    commons: commons,
    attributes: attributes,
    models:models,
    account: account,
    settings: settings,
    errors: errors,
    cookie:cookie,
    checkin:checkin,
    car:car,
    notifications:notifications,
    bookingstepper: bookingstepper,
    orders:orders,
    pages:pages
}

export default lang