export default [
    {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "CmsAuth" */ '@/views/Auth/Login.vue'),
    },
    {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "CmsAuth" */ '@/views/Auth/ForgotPassword.vue'),
    }
];