import apiClient from '@/plugins/apiClientDrive'


export default {
    namespaced: true,
    state:{
        errors: null,
        message: null,
        single: null,
        list: [],
    },
    getters:{
        errors: state => state.errors,
        message: state => state.message,
        single: state => state.single,
        list: state => state.list,
    },
    mutations:{
        error(state, err) {
            console.log('mutation error',err);
            state.message = err.response.data.message;
            state.errors = err.response.data.errors;
        },
        setList(state, data) {
            console.log('mutation setList',data);
            state.list = data;
        },
        setSingle(state, data) {
            console.log('mutation setSingle',data);
            state.single = data;
        },
        reset(state){
            console.log('mutation reset');
            state.errors = {}
            state.message = null
            state.success = null
        },
    },
    actions:{
        resetErrors({commit}){
            commit('reset')
        },
        getList({commit}){
            return new Promise((resolve, reject) => {
                console.log('GET USER DRIVERS')
                apiClient.get("users/me/drivers").then(resp => {
                        commit('setList', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        createSingle({commit},driver){
            return new Promise((resolve, reject) => {
                console.log('GET USER DRIVERS')
                apiClient.post("users/me/drivers",driver).then(resp => {
                        commit('setSingle', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getSingle({commit},driver){
            return new Promise((resolve, reject) => {
                console.log('GET USER DRIVERS')
                apiClient.get("users/me/drivers/"+driver.id).then(resp => {
                        commit('setSingle', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        deleteSingle({commit},driver){
            return new Promise((resolve, reject) => {
                console.log('GET USER DRIVERS')
                apiClient.delete("users/me/drivers/"+driver.id).then(resp => {
                        commit('setSingle', null);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        updateSingle({commit},driver){
            return new Promise((resolve, reject) => {
                console.log('GET USER DRIVERS')
                apiClient.patch("users/me/drivers/"+driver.id, driver).then(resp => {
                        commit('setSingle', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
    },
}