import commons from './en/commons.json'
import attributes from './en/attributes.json'
import models from './en/models.json'
import account from './en/account.json'
import settings from './en/settings.json'
import errors from './en/errors.json'
import car from './en/car.json'
import checkin from './en/checkin.json'
import cookie from './en/cookie.json'
import orders from './en/orders.json'
import pages from './en/pages.json'
import bookingstepper from './en/bookingstepper.json'
import notifications from './en/notifications.json'

import { en } from 'vuetify/locale'


const lang = {
    $vuetify: en,
    pages: pages,
    commons: commons,
    notifications: notifications,
    attributes: attributes,
    models:models,
    checkin:checkin,
    account: account,
    orders: orders,
    settings: settings,
    errors: errors,
    cookie:cookie,
    car:car,
    bookingstepper: bookingstepper,
}

export default lang