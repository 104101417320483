import fullSettings from './settings.json';
import apiClient from '@/plugins/apiClient'
let basePath = "/settings";

export default {
    namespaced: true,
    state:{
         hasSettings: (sessionStorage.getItem('hasSettings') === 'true'),
         prefooter: sessionStorage.getItem('prefooter'),
         footer: sessionStorage.getItem('footer'),
         primary: sessionStorage.getItem('primary'),
         social: sessionStorage.getItem('social'),
         direct: sessionStorage.getItem('direct'),
         secondary: sessionStorage.getItem('secondary'),
    },
    getters:{
        hasSettings: state => state.hasSettings,
        footer: state => (state.footer) ? JSON.parse(state.footer) : null,
        prefooter: state => (state.prefooter) ? JSON.parse(state.prefooter) : null,
        primary: state => (state.primary) ? JSON.parse(state.primary) : null,
        secondary: state => (state.secondary) ? JSON.parse(state.secondary) : null,
        direct: state => (state.direct) ? JSON.parse(state.direct) : null,
        social: state => (state.social) ? JSON.parse(state.social) : null,
    },
    mutations:{
        setFull(state, resp) {
            console.log('setFullSetting',resp)
            
            resp.data.forEach((element) => {
                state[element.slug] = JSON.stringify(element)
                sessionStorage.setItem(element.slug, JSON.stringify(element));
            });
            state.hasSettings = true;
            sessionStorage.setItem('hasSettings',true);
            console.log('setFullSetting', state);

        }
    },
    actions:{
        getSettings({commit}, data){
            commit('setFull', fullSettings);
            return new Promise((resolve, reject) => {
                 console.log('GET LIST :'+data)
                 apiClient.get(basePath).then(resp => {
                      commit('setFull', resp.data);
                      resolve(resp);
                  })
                  .catch(err => {
                      console.log('GET SINGLE', err)
                      commit('setFull', fullSettings);
                      reject(err);
                  });
            })
       },
    }
}