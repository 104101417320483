import axios from 'axios';
import Raven from 'raven-js';
import store from '@/store/index';
import router from "@/router";

const env = import.meta.env;
/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl =  env.VITE_APP_API_DRIVE_URL, contentType = 'application/json' ) => {

    const authenticated = store.getters['driveAuth/isAuthenticated']
    
    var userLang = localStorage.getItem('locale');

    const options = {
        baseURL: baseUrl,
        withCredentials: true,
        contentType: contentType,
        credentials: 'same-origin',
        headers: {
            'Content-Type': contentType,
        }
    };

    store.dispatch('loading/set',true);
    console.log('loading',true)

    if (authenticated) {
        options.headers = {
            "Content-Type": contentType,
            "Accept": 'application/json',
            "X-APP-LANGUAGE": userLang,
            "Access-Control-Allow-Credentials": true, 
            'Access-Control-Allow-Origin': true,
            "Authorization": 'Bearer ' + localStorage.getItem('token')
        };
    }else{
        options.headers = {
            "Content-Type": contentType,
            "Accept": 'application/json',
            'Access-Control-Allow-Origin': true,
            "Access-Control-Allow-Credentials": true,
            "X-APP-LANGUAGE": userLang,
        };
    }
     console.log('API CLIENT options ' , options)

    const client = axios.create(options);

    // Add a request interceptor
    client.interceptors.request.use(
        requestConfig => requestConfig,
        (requestError) => {
            Raven.captureException(requestError);
            return Promise.reject(requestError);
        },
    );

    // Add a response interceptor
    client.interceptors.response.use(function (response) {
        if(response.status === 201){
            // store.dispatch('snackBars/addSnack', {message: "SUCCESS", type: 'success', err: null})
        }
        if(response.status === 202){
            // store.dispatch('snackBars/addSnack', {message: "SAVED", type: 'success', err: null})
        }
        if(response.status === 204){
            // store.dispatch('snackBars/addSnack', {message: "SAVED", type: 'success', err: null})
        }
        store.dispatch('loading/set',false);
        return response;
    },
    (error) => {
        if(!error.response){
            store.dispatch('snackBars/addSnack', {message: 'errors.api-connection'+' '+error, type: 'error', err: 'invalid API'})
        }else{
            console.log("error.response",error.response)
        if (error.response.status === 401) {
            store.dispatch('snackBars/addSnack', {message: error.response.data.message, type: 'error', err: '401', errors: error.response.data.errors })
            store.dispatch('driveAuth/forceLogout',false)
            // if (router.currentRoute.path !== '/account/login') {
            //     router.push('/account/login')
            // }
        }

        if (error.response.status === 403) {
            store.dispatch('snackBars/addSnack', {message: error.response.data.message, type: 'error', err: '403'})

        }

        if (error.response.status === 405 ) {
            store.dispatch('snackBars/addSnack', {message: error.response.data.message, type: 'error', err: '405'})
        }

       if (error.response.status === 422) {
           store.dispatch('snackBars/addSnack', {message: error.response.data.message, type: 'error', err: '422', errors: error.response.data.errors})
       }

       if (error.response.status === 404) {
        // router.push({name:"404"})
        store.dispatch('snackBars/addSnack', {message: error.response.data, type: 'error', err: '404'})
       }


        if (error.response.status === 429) {
            store.dispatch('snackBars/addSnack', error.response.data)
        }
        
        if (error.response.status === 413) {
            store.dispatch('snackBars/addSnack', {message: error.response.data, type: 'error', err: '413'})
        }

        if (error.response.status == 400) {
            
            if(error.response.data.errors){
                error.response.data.type = 'error';
                store.dispatch('snackBars/addSnack', error.response.data)
            }else if(error.response.data.error){
                console.error("error.response", error.response.data.error);
                store.dispatch('snackBars/addSnack', {message: error.response.data.error, type: 'error'})
            }    
        }

        if (error.response.status >= 500) {
            store.dispatch('snackBars/addSnack', error.response.data)
        }
        
    }

        store.dispatch('loading/set',false);
        return Promise.reject(error);
    });

    return client;
};

class ApiClient {
    constructor(baseUrl = null) {
        this.client = getClient(baseUrl);
    }

    get(url, conf = {}) {
        return this.client.get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    delete(url, conf = {}) {
        return this.client.delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    head(url, conf = {}) {
        return this.client.head(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    options(url, conf = {}) {
        return this.client.options(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    post(url, data = {}, conf = {}) {
        return this.client.post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    put(url, data = {}, conf = {}) {
        return this.client.put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    patch(url, data = {}, conf = {}) {
        return this.client.patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
    // Provide request methods with the default base_url
    get(url, conf = {}) {
        console.log('API CLIENT | GET ' , url, conf)
        return getClient().get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    delete(url, conf = {}) {
        console.log('API CLIENT | DELETE ' , url, conf)
        return getClient().delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    head(url, conf = {}) {
        console.log('API CLIENT | HEAD ' , url, conf)
        return getClient().head(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    options(url, conf = {}) {
        console.log('API CLIENT | OPTIONS ' , url, conf)
        return getClient().options(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    post(url, data = {}, conf = {}) {
        console.log('API CLIENT | POST ' , url, conf)
        return getClient().post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    upload(url, data = {}, conf = {}) {
        console.log('API CLIENT | UPLOAD ' , url, conf)
        var baseUrl =  env.VITE_APP_API_DRIVE_URL;
        return getClient(baseUrl,'multipart/form-data').post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    s3upload(url, data = {}, conf = {}) {
        console.log('API CLIENT | UPLOAD ' , url, conf)
        var baseUrl = env.VUE_APP_S3_URL;
        return getClient(baseUrl,'multipart/form-data').post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    put(url, data = {}, conf = {}) {
        console.log('API CLIENT | PUT ' , url, conf)
        return getClient().put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    patch(url, data = {}, conf = {}) {
        console.log('API CLIENT | PUT ' , url, conf)
        return getClient().patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },
};
