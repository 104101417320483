export default {
     namespaced: true,
     state:{
          loading: false
     },
     getters:{
          loading: state => state.loading
     },
     mutations:{
          setLoading(state,resp){
               console.log('mutation setLoading',resp);
               state.loading = resp
          },
     },
     actions:{
          set({commit},data){
               commit('setLoading',data)
          }
     }
}
