/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import logger from './logger';
console.log('logger',logger);
import vuetify from './vuetify'
import router from '../router'
import store from '@/store'
import { createHead } from '@unhead/vue'

import { createGtm } from '@gtm-support/vue-gtm';

const env = import.meta.env;
const idGTM = env.VITE_GOOGLE_TAG_MANGER_ID;

const head = createHead()
console.log("createHead",head);

export function registerPlugins (app) {
  app
    .use(vuetify)
    .use(store)
    .use(head)
    .use(router)
    .use(createGtm({
      id: idGTM,
    }))
}
