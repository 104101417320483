export default {
     namespaced: true,
     state:{
          cookies: {}
     },
     getters:{
          cookies: state => state.cookies
     },
     mutations:{
          setCookies(state, payload){
               console.log("setCookies",payload)
               state.cookies = payload
          },
          addCookies(state, payload){
               state.cookies[payload.name] = payload.value;
          },
          removeCookies(state, name){
               delete state.cookies[name];
          }
     },
     actions:{
          addCookie({commit}, payload){
               let date = new Date();
               let name = payload.name;
               let value = payload.value;
               commit('addCookies', {name, value});
               date.setTime(date.getTime() + (365*24*60*60*1000));
               document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
          },
          removeCookie({commit}, payload){
               console.log('removeCookie', payload)
               let date = new Date();
               let name = payload;
               date.setTime(date.getTime() - (365*24*60*60*1000));
               document.cookie = `${name}=null;expires=${date.toUTCString()};path=/`;
               commit('removeCookies', name);
          },
          getCookies({commit}){
               let cookies = document.cookie;

               let cookiesArray = {};
               cookies = cookies.split(';');
               if(cookies.length >= 1){
                 cookies.forEach(element => {
                     var el = element.trim();
                     el = el.split('=');

                     if(el[1]== 'true'){
                         el[1] = true
                     }
                     if(el[1]== 'false'){
                         el[1] = false
                     }

                     cookiesArray[el[0]] = el[1];
                 });
               }
               commit('setCookies',cookiesArray);
          }
     }
}
