// Composables
import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
const env = import.meta.env;

import cmsAuth from './cmsAuth'
import cmsModels from './cmsModels'
import userRoutes from './accountUser'
import bookingRoutes from './bookingProcess'
import searchCarsRoutes from './searchCars'

const routes = [
  {
    path: '/cms',
    component: () => import('@/layouts/dashboard/Default.vue'),
    children : cmsModels,
    meta: {
        auth: true,
    },
  },
  {
    path: '/auth',
    component: () => import('@/layouts/dashboard/Default.vue'),
    children: cmsAuth,
  },
  {
    path: '/account',
    name: 'account',
    redirect: '/account/my',
    component: () => import('@/layouts/default/Default.vue'),
    children: userRoutes,
  },
  {
    path: '/booking',
    component: () => import('@/layouts/booking/Default.vue'),
    children: bookingRoutes,
  },
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        redirect: {name: 'primary', params:{slug: localStorage.getItem('locale')}},
        alias: ['home','home-page'],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
      },
      {
        path: 'search',
        name: 'search',
        redirect: {name: 'secondary', params:{slug: localStorage.getItem('locale'), slug2: 'search'}},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Search" */ '@/views/Pages/Single.vue'),
        children: searchCarsRoutes
      },
      {
        path: '/:slug',
        name: 'page',
        params: ':slug',
        component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Single.vue'),
        children: [
          {
            path: '',
            name: 'primary',
            component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
            children: [
              {
                path: ':slug2',
                name: 'secondary',
                params: ':slug2',
                component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
                children: [
                  {
                    path: ':slug3',
                    name: 'tertiary',
                    params: ':slug3',
                    component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
                    children: [
                      {
                        path: ':slug4',
                        name: 'quaternary',
                        params: ':slug4',
                        component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
                        children: [
                          {
                            path: ':slug5',
                            name: 'fithnary',
                            params: ':slug5',
                            component: () => import(/* webpackChunkName: "Pages" */ '@/views/Pages/Primary.vue'),
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },    
        ]
      }
    ],
  }
]

const router = createRouter({
  history: createWebHistory(env.VITE_BASE_URL),
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            {
              el: to.hash,
              behavior: 'smooth',
            }
          )
        }, 200)
      })
    }else{
      return { top: 0 }
    }
  }
});



router.beforeEach( (to, from, next) => {

  let isAuthenticated = store.getters['auth/isAuthenticated']
  let isAuthenticatedDrive = store.getters['driveAuth/isAuthenticated']
  let hasSettings = store.getters['settings/hasSettings']

  if(!hasSettings){
    store.dispatch('settings/getSettings');
  }

  if(isAuthenticatedDrive){
    
    store.dispatch('driveAuth/refresh');
    console.log('driveAuth/refresh');
    
    let timerAuth = setInterval(function() {
      store.dispatch('driveAuth/refresh');
      console.log('driveAuth/refresh', 'interval');
    }, 60 * 1000); // 60 * 1000 milsec

    console.log('driveAuth/refresh timerAuth', timerAuth);

  }

  if(to.query){
    console.log("saveQueryParams", to.query);
    store.dispatch('driveAuthOrders/saveQueryParams', to.query);
  }

  // console.log('isAuthenticated', isAuthenticated);
  // console.log('from',from)
  // console.log('to',to)
  // se authenticazione richiesta e non è autenticato vai a login
  if(to.meta){
   if(to.meta.auth){
      if( to.meta.auth==true && !isAuthenticated){
        store.dispatch('snackBars/addSnack', {message: 'Authentication required', type: 'error', err: 'Login'})
          next({name:"login"});
          console.log('go to login');
        }
      }
      if(to.meta.auth==false && isAuthenticated){
        store.dispatch('snackBars/addSnack', {message: 'Authenticated user', type: 'success', err: 'Login'})
        next({name:"Home"});
      }
      if(to.meta.driveAuth==true && !isAuthenticatedDrive){
        store.dispatch('snackBars/addSnack', {message: 'Authentication required', type: 'error', err: 'Login'})
        next({name:"account.login"});
      }
      if(to.meta.driveAuth==false && isAuthenticatedDrive){
        store.dispatch('snackBars/addSnack', {message: 'Authenticated user', type: 'success', err: 'Login'})
        next({name:"account.my"});
      }
   }

  if(to.fullPath.startsWith("/it/")){
    localStorage.setItem('locale',"it")
  }
  if(to.fullPath.startsWith("/en/")){
    localStorage.setItem('locale',"en")
  }
  if(to.fullPath.startsWith("/ru/")){
    localStorage.setItem('locale',"ru")
  }
  if(to.fullPath.startsWith("/pt/")){
    localStorage.setItem('locale',"pt")
  }

  if(to.fullPath.endsWith("/it")){
    localStorage.setItem('locale',"it")
  }
  if(to.fullPath.endsWith("/en")){
    localStorage.setItem('locale',"en")
  }
  if(to.fullPath.endsWith("/ru")){
    localStorage.setItem('locale',"ru")
  }
  if(to.fullPath.endsWith("/pt")){
    localStorage.setItem('locale',"pt")
  }
 
  next();
  
});

export default router