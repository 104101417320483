export default {
    namespaced: true,
    state:{
         snackbars: [],
         snackbar:{
              message: '',
              type: 'info',
              visible: true,
              text: null,
              errors: null,
              timeout: 3000,
         }
    },
    getters:{
         snackbars: state => state.snackbars,
         snackbar: state => state.snackbar
    },
    mutations:{
         addSnackBars(state,resp){
              console.log('mutation addSnackBars',resp);
              state.snackbars.push(resp)
         },
    },
    actions:{
         clear(state){
              state.snackbars = [];
         },
         addSnack({commit,state},payload) {

              if(!payload.message){
                   payload.message = state.snackbar.message
              }
              if(!payload.timeout){
                   payload.timeout = state.snackbar.timeout
              }
              if(!payload.type){
                   payload.type = state.snackbar.type
              }
              if(!payload.visible){
                   payload.visible = state.snackbar.visible
              }
              if(!payload.text){
                   payload.text = state.snackbar.text
              }
              if(!payload.errors){
                    payload.text = state.snackbar.errors
              }
              if(!payload.date){
               payload.date = new Date()
              }
              commit('addSnackBars',payload)
         }
    }
}