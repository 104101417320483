export default [
    {
      path: '/search/result',
      name: 'search-result',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "Search" */ '@/views/Pages/SearchResult.vue'),
    },
    {
      path: '/search/result/selected',
      name: 'search-result-selected',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "Search" */ '@/views/Pages/Booking/Selected.vue'),
    }
  ]