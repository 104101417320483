import apiClient from '@/plugins/apiClientDrive'
import moment from 'moment';
import countries from "@/plugins/countries.json"


function splitPhoneNumber(phoneNumber) {

    console.log('splitPhoneNumber',phoneNumber)
    let phone = {};

    countries.forEach(element => {
        if(phoneNumber.includes(element.prefix)){
            console.log('splitPhoneNumber',element)
            phone = {
                prefix: element.prefix,
                country: element.country,
                iso: element.iso,
                phone: phoneNumber.replace(element.prefix,'')
            }
            return true;
        }
    });

    console.log('splitPhoneNumber',phone)

    return phone

}


export default {
     namespaced: true,
     state:{
        authenticated: localStorage.getItem('authenticated'),
        token: localStorage.getItem('token'),
        account: (localStorage.getItem('account')) ? JSON.parse(localStorage.getItem('account')) : null,
        errors: {},
        message: null,
        success: null,
     },
     getters:{
        isAuthenticated: state => (state.authenticated) ? true : false,
        account: state => state.account,
        errors: state => state.errors,
        message: state => state.message,
        success: state => state.success,
     },
     actions:{
        login({commit},data){
            return new Promise((resolve, reject) => {
                console.log('LOGIN USER',data)
                apiClient.post("auth/token", data).then(resp => {
                        commit('setToken', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('store auth actions AUTH_LOGIN error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        refresh({commit}){

            if( moment() >= moment(localStorage.getItem('expires_at'))){
                console.log('IS TO REFRESH  TOKEN', [ moment(), moment(localStorage.getItem('expires_at'))])
                return new Promise((resolve, reject) => {
                    let data = {
                        "refreshToken": localStorage.getItem('refreshToken')
                    }
                    console.log('REFRESH USER TOKEN', data)
                    apiClient.post("auth/refresh-token", data).then(resp => {
                            commit('setToken', resp.data);
                            resolve(resp);
                        })
                        .catch(err => {
                            console.log('store auth actions AUTH_LOGIN error ', err)
                            commit('error', err);
                            reject(err);
                        });
                })
            }else{
                console.log('IS NOT TO REFRESH  TOKEN')
            }
        },
        signup({commit},data){
            return new Promise((resolve, reject) => {
                console.log('signup USER',data)
                apiClient.post("users", data).then(resp => {
                        commit('account', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('signup AUTH_signup error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getAccount({commit}){
            return new Promise((resolve, reject) => {
                console.log('get USER data')
                apiClient.get("users/me").then(resp => {
                        commit('account', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get Account error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        updateAccount({commit}, data){
            return new Promise((resolve, reject) => {
                console.log('updateAccount USER data')
                apiClient.patch("users/me", data).then(resp => {
                        commit('account', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('updateAccount error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        updatePassword({commit}, data){
            return new Promise((resolve, reject) => {
                console.log('updatePassword USER data')
                apiClient.put("users/me/password", data).then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('updatePassword error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        resetPassword({commit}, data){
            return new Promise((resolve, reject) => {
                console.log('updatePassword USER data')
                apiClient.put("users/password", data).then(resp => {
                        commit('forgotPassword',resp)
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('updatePassword error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        deleteAccount({commit}){
            return new Promise((resolve, reject) => {
                console.log('DELETE USER data')
                apiClient.delete("users/me").then(resp => {
                        commit('logout')
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('DELETE USER ERROR ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        newContact({commit}, data){
            return new Promise((resolve, reject) => {
                console.log('DELETE USER data')
                apiClient.post("crm/leads", data).then(resp => {
                        commit('newcontact', resp)
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('DELETE USER ERROR ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        forceLogout({commit}) {
            commit('logout')
        },
        resetErrors({commit}){
            commit('reset')
        }
     },
     mutations:{
        error(state, err) {
            console.log('mutation error',err);
            if(err.response.data.message){
                state.message = err.response.data.message;
                state.errors = err.response.data.errors;
            }else{
                state.message = err.message;
                state.errors = err.statusText;
            }
            state.success = false;
            
        },
        reset(state){
            state.errors = {}
            state.message = null
            state.success = null
        },
        setToken(state, token) {
            console.log('mutation setToken',token);
            localStorage.setItem('token', token.accessToken)
            
            let expirationDate =  moment().add( parseInt(token.expiresIn), 's').format();
            console.log('expirationDate', expirationDate)
            localStorage.setItem('expires_at', expirationDate);

            localStorage.setItem('refreshToken', token.refreshToken)
            localStorage.setItem('authenticated', 'true')
            state.authenticated = true
            state.token = token.accessToken
            state.errors = {}
            state.message = null
        },
        account(state, data){
            console.log('mutation account', data);

            let phone = splitPhoneNumber(data.phone)
            data.phone = phone.phone;
            data.phone_prefix = phone.prefix;

            localStorage.setItem('account', JSON.stringify(data))
            state.account = data
            state.errors = {}
            state.message = null
        },
        newcontact(state, resp){
            console.log('mutation newcontact',resp);
            state.success = true
            state.message = "commons.newcontact-success-message"
        },
        success(state,resp) {
            console.log('mutation success',resp);

            localStorage.setItem('token', resp.access_token)
            localStorage.setItem('locale', resp.language)
            localStorage.setItem('timezone', resp.timezone)
            localStorage.setItem('authenticated', true)
            state.errors = {}
            state.message = null
            state.authenticated = true
        },
        logout(state) {
            console.log('LOGOUT FORCE')
            state.errors = {}
            state.message = null
            
            state.authenticated = false;
            state.token = null;
            state.account = null;
            
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('authenticated')
            localStorage.removeItem('account')
            localStorage.removeItem('expires_at')
        },
        forgotPassword(state, resp){
            console.log('mutation forgotPassword',resp);
            state.success = true;
            state.message = "commons.forgot-password-reset-success"
            state.errors = {}
        },
        resetPassword(state, resp){
            state.errors = {}
            console.log('mutation resetPassword',resp);
            state.success = true;
            state.message = resp.message
        }
     }
}