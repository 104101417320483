export default [
    {
        path: '',
        name: 'cms.dashboard',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Dashboard.vue'),
    },
    {
        path: 'dashboard',
        name: 'dashboard.home',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Dashboard.vue'),
    },
    {
        path: 'users',
        name: 'cms.users',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Users/List.vue'),
    },
    {
        path: 'users/:id',
        name: 'cms.users.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Users/Single.vue'),
    },
    {
        path: 'assets',
        name: 'cms.assets',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Assets/List.vue'),
    },
    {
        path: 'assets/:id',
        name: 'cms.assets.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Assets/Single.vue'),
    },
    {
        path: 'pages',
        name: 'cms.pages',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Pages/List.vue'),
    },
    {
        path: 'pages/:id',
        name: 'cms.pages.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Pages/Single.vue'),
    },
    {
        path: 'categories',
        name: 'cms.categories',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Categories/List.vue'),
    },
    {
        path: 'categories/:id',
        name: 'cms.categories.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Categories/Single.vue'),
    },
    {
        path: 'settings',
        name: 'cms.settings',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Settings/List.vue'),
    },
    {
        path: 'settings/:id',
        name: 'cms.settings.single',
        params: ':id',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/Cms/Settings/Single.vue'),
    },
];